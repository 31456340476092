#snackbar-container {
  position: fixed;
  left: 20px;
  bottom: 0;
  z-index: 99999;
}

.snackbar {
  overflow: hidden;
  clear: both;
  min-width: 288px;
  max-width: 568px;
  //cursor: pointer;
  //spointer-events: none !important;

  opacity: 0;

  &.snackbar-opened {
    height: auto;
    opacity: 1;
  }
}

@media (max-width: 767px) {
  #snackbar-container {
    left: 0px !important;
    right: 0px;
    width: 100%;

    .snackbar {
      min-width: 100%;
    }

    [class="snackbar snackbar-opened"] {
      ~ .snackbar.toast {
        margin-top: 20px;
      }

      border-radius: 0;
      margin-bottom: 0;
    }
  }
}


.snackbar-content {
    display: block;
    padding: .8rem 1.5rem;
    /*
    padding-top: 0.8rem;
    padding-right: 0.5rem;
    padding-bottom: 0.8rem;
    padding-left: 0.5rem;
    */


    margin-top: 3px;
    font-size: 1.15rem;
    color: #fff;
    background-color: $blue-vs;
    border-radius: 2px;
    opacity: 0.90;
}


.trackNotifyCloseIcon {
  color: white;
  position: absolute;
  top: 8px;
  right: 0px;

  background-color: transparent;
  border: 0;
  -webkit-appearance: none;

}


.trackNotifyCloseIcon:hover, .trackNotifyCloseIcon:focus {
  color: black;
  text-decoration: none;
  opacity: .75;
  cursor:pointer;
}



.trackNotifyContent {
  color: white !important;
  margin: 0px !important;
}
/*# sourceMappingURL=snackbar.css.map */
