
$bluehover: #8ebaff;
$blue: #0a6ebd;

.media-section-link {
color: $blue !important;
&:hover {
  color: $bluehover !important;
}
transition:0.2s; /* referencing transitions properties from .btn class, social icon buttons used on media.html */
}



p {
    color: $black-color !important;
    margin-bottom: 20px;
}

.about-section {
  padding: 5px 0;
}

.avs-jumbotron, .helpus-jumbotron {
	padding-top: 40px;

	.buttons {
		padding-top: 2rem;
	}
}

.contact-email {
  display: none;
  text-align: left;
}

.contact-row {
  .info {
    padding-top: 0;

    &.contact-mail-div {
      padding-bottom: 0;
    }
  }
}

.board-pills {
  .nav-item {
    width: 25%;
  }
  &.nav-pills:not(.flex-column) .nav-item + .nav-item:not(:first-child) {
    margin-left: 0;
  }
}


.features-1 {
  padding-top: 50px !important;
}



@media (max-width: 460px) {
  .pct-btn-text-wrap {
    white-space: normal !important;
  }
}



@media (min-width:768px) and (max-width: 995px) {
  #pageHeaderBtnBlock div:nth-child(2) > a {
    padding-right: .25rem !important;
    padding-left: .25rem !important;
  }
}



#aboutPageHeader {
  height: 65vh !important;
}

@media (max-width: 768px) {
  #aboutPageHeader {
    height: 100vh !important;
  }
}

@media (max-width: 415px) {
  #aboutPageHeader {
    height: 110vh !important;
  }
}

@media (max-width: 320px) {
  #aboutPageHeader {
    height: 150vh !important;
  }
}



/* /about/bull */

.about-bull-graph {
  line-height: 1.5;
}
.bull-logo-header {
  width: 166px;
}

.bull-logo-in-text {
  width: 40px;
}


/* /help-us */

.donate-dist-title {
  font-weight: 700;
  font-family: "Roboto Slab", "Times New Roman", serif;
}

/* Help-us Donation Page */

#customAmountModal {
  .card-header:first-of-type {
    width: 100%;
  }
}

.donate-btn-group {
  display: flex;
  .btn {
    flex: 1;
  }
  .btn.btn-info.active.focus {
    color: #ffffff;
    background-color: #008fa1;
    border-color: #008394;
  }
}

.other-ways-help {
  .nav-pills {
    .nav-item {
      .nav-link {
        line-height: 35px;
      }
    }
  }
}