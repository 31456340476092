.footer-black {
	&.footer-votesmart {

		h3 {
			color: $white;
		}

		a.about-link {
			color: $brand-footerlb;
		}

	    background: $blue-vs;
	    background: radial-gradient(ellipse at center, $blue-900 0%, $blue-vs 100%);
	    background-size: 550% 450%;

	    .navbar {
	    	margin-bottom: 0;

	    	&.navbar-footer {
				box-shadow: none;
				-webkit-box-shadow: none;
	    		background-color: transparent !important;
	    		color: $brand-footerlb;
	    		padding: 0;

	    		.dropdown-toggle {
	    			&.nav-link {
	    				font-weight: bolder;
	    				font-weight: 800;
	    				font-size: 14px;
	    			}
	    		}
		    }
		}

		.blockquote {
			color: $brand-footerlb;
		}

		.research-buttons {
			.btn {
				font-weight: bold;
			}
		}

		#googleSiteSearchFooterInput {
			padding: 0.5rem;

			&:focus {
				background-color: $white;
			}
		}
	}
}


.footer-bottom {
	address {
		margin-top: 1rem;
	}


	address, p {
		color: $white !important;
		font-size: 11px;
		margin-bottom: 0;

		a {
			color: $brand-footerlb;
			font-weight: bold;
		}
	}

	.btn-lg {
		margin-top: 1rem;
		font-weight: bold;
	}
}

@media all and (max-width: 991px) {
	.navbar-footer {
		.dropdown-menu {
			background-color: $white !important;
			height: auto !important;

		}

	}
}


.new-search-site-footer-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #737373;
	font-size: 1rem !important;
}

.new-search-site-footer-input::-moz-placeholder { /* Firefox 19+ */
  color: #737373;
	font-size: 1rem !important;
}

.new-search-site-footer-input:-ms-input-placeholder { /* IE 10+ */
  color: #737373;
	font-size: 1rem !important;
}

.new-search-site-footer-input:-moz-placeholder { /* Firefox 18- */
  color: #737373;
	font-size: 1rem !important;
}






.star-spangled-bottom-footer {
	background: url('/static/images/general/star-spangled-banner-image-cropped.png');
	background-size: cover;
	padding: 2vw 0;
}
