#ispy-header {
	z-index: 4;
	margin-top: 0;
	background-color: $light-blue-50 !important;
	-webkit-box-shadow: inset 0 8px 6px -8px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
	box-shadow: inset 0 8px 6px -8px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);

}


.hamburger-navbar-homepage {
	top: 125px !important;
}

@media (max-width: 765px) {
	.hamburger-navbar-homepage {
		top: 100px !important;
	}
}



#track-header {
	color: $white;
	background-color: $blue-vs !important;

	.title {

		text-shadow: 2px 2px 3px $black;
		text-transform: uppercase;
		margin: 0;
		font-size: 1.6rem;
		line-height: 2rem;
		@media (max-width: 768px) {
			font-size: 1.4rem!important;
		}
		@media (max-width: 576px) {
			font-size: 0.8rem!important;
		}
		color: $white;
	}

	.section {
		padding: 35px 0;
	}
}

#main-header {

	z-index: 5;
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
	box-shadow: none;
	-webkit-box-shadow: none;


	a.brand-link {
		padding: 0;

		#image-logo {
			height: 100%;
		}
	}

	.hamburger-navbar {
		top: 96px;
	}

	.vs-nav-button {
		a {

			padding: 0;
			padding-right: 0.5rem;

			img {
				max-height: 70px;
				filter: brightness(100%);
				-webkit-filter: brightness(100%);

				&:hover {
					filter: brightness(80%);
					-webkit-filter: brightness(80%);
				    -webkit-transition: all 1s ease;
				    -moz-transition: all 1s ease;
				    -o-transition: all 1s ease;
				    -ms-transition: all 1s ease;
				    transition: all 1s ease;
				}
			}
		}
	}


	.navbar {
	 min-height: 100px !important;
	}

	.navbar-brand {
	 height: 100px !important;
	}

	@media (max-width: 1199px) {
		.navbar {
		  min-height: 80px !important;
	   }
		 .navbar-brand {
 		 height: 80px !important;
 		}
	}


	@media (max-width: 515px) {
		.navbar {
		min-height: 60px !important;
	 }
	 .navbar-brand {
		height: 60px !important;
	 }
 }

 @media (max-width: 365px) {
	 .navbar {
	 min-height: 50px !important;
	}
	.navbar-brand {
	 height: 50px !important;
	}
}





}





#helpus-top, #helpus-home {

	background-color: $red-700 !important;
	padding: 0;
	z-index: 6;
	margin-bottom: 0;

	#donate-header-link {
		width: 100%;
		text-align: center;

		.help-us {

			background: $red-700 url('/static/images/helpus/bg-help.png') 50% 0;

			.donate-header {
				width: 100%;
				color: $white;
				text-shadow: 2px 2px 3px $black;
				text-transform: uppercase;
				margin: 0;
				font-size: 1.8rem;
				line-height: 4rem;

				@media (max-width: 768px) {
					font-size: 1.4rem!important;
				}

				@media (max-width: 576px) {
					font-size: 0.8rem!important;
				}


				small {
					font-size: 50%;
					color: $white;
					vertical-align: top;

					i.material-icons {
						font-size: inherit;
						vertical-align: 20%;
						width: 5px;
					}
				}

			&.homepage {
				line-height: 2rem;

				@media (max-width: 576px) {
				   font-size: 1.2rem!important;
				}
			}
			}
		}

	}
}




.contact-us-icon-text {
	display:none
}

@media (max-width: 991px) {

	.contact-us-icon-text {
		margin-top: 5px;
		top: 2px;
		position: relative;
		margin-right: 3px;
		margin-left: 10px;
		display: unset;
		font-size: 1.25em;
	}

	#contactUsIcon {
		font-size: 1.75em !important;
	}

}


#myVoteSmartImg {
	min-width: 8.25rem;
}



/* ------ for new header -------- */


@media(max-width: 768px) {

	.myvotesmart-btn-header {
		display: none !important;
	}

	.myvotesmart-btn-header-mobile {
		display: unset !important;
	}


	.myvotesmart-btn-header-noauth:after {
		content: "MYVOTESMART";
	}


}



/*
@media (max-width: 470px) {

	.myvotesmart-btn-header-noauth {
		padding-right: 5px;
		padding-left: 5px;
	}

	.myvotesmart-btn-header-auth {
		padding-right: 5px;
		padding-left: 5px;
	}
}
*/



@media(min-width: 768px) {

	.myvotesmart-btn-header-mobile {
		display: none !important;
	}

	.myvotesmart-btn-header {
		display: unset !important;
	}

	.myvotesmart-btn-header-noauth:after {
		content: "MYVOTESMART LOGIN";
	}
}






@media(max-width: 477px) {
	.have-our-backs-header-btn {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media(max-width: 395px) {

	.have-our-backs-header-btn {
		font-size: 14px !important;
	}

	.myvotesmart-btn-header-noauth {
		font-size: 14px !important;
	}

	.myvotesmart-btn-header-auth {
		font-size: 14px !important;
	}
}



@media(max-width: 320px) {

	.header-btn-col {
		padding-right: 5px;
		padding-left: 5px;
	}
}




@media(max-width: 450px) {
	.myvotesmart-btn-header-auth {
		padding-right: 5px;
		padding-left: 5px;
	}
	.myvotesmart-btn-header-noauth {
		padding-right: 5px;
		padding-left: 5px;
	}
}



/*
@media (max-width: 475px) {
	.have-our-backs-header-btn {
		padding-left: 2px;
		padding-right: 2px;
	}
}

@media (max-width: 350px) {
	.header-btn-col {
		padding-left: 5px;
		padding-right: 5px;
	}
}
*/



/* need to fix a bunch of header stuff at width <= 320px */
