$table_header_color_1: black;

#votesPaginationList {
  .page-item {
    li, a {
      font-size: 1.0rem;
    }
  }
}

.ellipses-list-item {
	padding-right: 11px !important;
	padding-left: 11px !important;
}



#categoriesDropdown {
	span {
		>a {
			>i {
				vertical-align: middle;
				padding: 5px;
			}
		}
	}
  .btn {
    font-weight: 700;
  }
}

.about-key-votes-selection-list {
	font-size: 1rem !important;
}


@media (max-width: 520px) {

	.pagination {
		>li {
			>a {
				font-size: .95rem !important;
			}
		}
	}
}



@media (max-width: 365px) {
  .pagination {
		>li {
			>a {
				padding: 0px 7px !important;
			}
		}
	}
}



.votes-table-section-header {
  padding-left: 8px !important;
}


#categoriesDropdownIconsBreak {
  display: none;
}
@media (max-width: 345px) {
  #categoriesDropdownIconsBreak {
  	display: unset;
  }
}


/* styling for new votes accordion */
.vote-congaction-name-icon {
  float: none !important;
}

// following the styling in _misc.scss here but bummping up darken % parm so it's more perceptible
.vote-title-link {
  color: $link-color !important;
  &:hover,
  &:focus{
      color: darken($link-color, 10%) !important;
      text-decoration: none !important;
  }
}

.vote-dates-table-row {
  color: white !important;
}

/* for dates in accordion header */
@media (max-width: 995px) {
  .v-congstatus-statusdate {
    display: none !important;
  }

  .vote-dates-table-row {
    color: unset !important;
  }
}


.votes-chosen-category-title {
  h4 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  hr {
    margin-top: 0px !important;
  }
}



.key-votes-table {
  td, th {
    font-size: 16px !important;
  }
}


#clearChosenVoteCategory {
  font-size: 0.6875rem !important;
}


.vote-header-collapse-item {
  h5 {
    hyphens: auto;
    font-weight: 700;
  }
}

.vote-header-collapse-item {
  i {
    margin: 10px !important;
  }
}


.alert-warning {
  font-size: 18px !important;
  font-weight: 700;
}


// just covering our bases for anything smaller than an iPhone5
@media (max-width: 319px) {

  h5 {
    font-size: 5vw !important;
  }


  .key-votes-table {
    td, th {
      font-size: 4.5vw !important;
    }
  }

  .alert-warning {
    font-size: 5vw !important;
  }

}


.key-votes-card-header {
  padding-top: 10px !important;
}




@media (min-width: 630px) {
  .candidate-votes-congaction-col {
    padding-left: 0px;
    padding-right: 0px;
  }
}
