#truncatedMainPublicStatementsTable {
  display: none;
}
@media (max-width: 500px) {
  #truncatedMainPublicStatementsTable {
    display: unset;
  }

  #mainPublicStatementsTable {
    display: none;
  }
}



// for highlight filter on statement detail
.highlight {
  background: #ff0;
}


.statements-search-form {
  .form-control::-webkit-input-placeholder {
    color: black !important;
  }
}


.btn-bull-statements {
  background-color: white !important;
  padding: 12px 12px !important;
  border-style: solid;
  border-width: 1px;
  border-color: #002664;
}

.btn-bull-statements:focus, .btn-bull-statements:active, .btn-bull-statements:hover, .btn-bull-statements.btn-bull-statements-default:focus, .btn-bull-statements.btn-bull-statements-default:active, .btn-bull-statements.btn-bull-statements-default:hover {
    -webkit-box-shadow: 0 14px 26px -12px rgba(145, 145, 145, 0.2), 0 4px 23px 0px rgba(145, 145, 145, 0.2), 0 8px 10px -5px rgba(145, 145, 145, 0.2);
    box-shadow: 0 14px 26px -12px rgba(145, 145, 145, 0.2), 0 4px 23px 0px rgba(145, 145, 145, 0.2), 0 8px 10px -5px rgba(145, 145, 145, 0.2);
}
