.search-results-right-side-list > li {
  line-height: 1.5 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-weight: 700 !important;
}

.search-results-right-side-list > li > a {
  margin-right: 0px !important;
}

.search-results-right-side-list > li > strong {
  margin-right: 0.5rem !important;
  font-weight: 300 !important;
}


.state-facts-search-results > li {
  line-height: 1.5 !important;
  padding-left: 1rem !important;
  padding-right: 0px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-weight: 700 !important;
}

.state-facts-search-results > li > strong {
  margin-right: 0.5rem !important;
  font-weight: 300 !important;
}
