/* styling for subnav */

.governmentSectionNavBar {
  background-color: #002664;
  overflow: auto;
  white-space: nowrap;
  padding: 0.25rem 0.5rem;
}

.governmentSectionNavBar a {
  display: inline-block;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  font-weight: 700;
  color: white;
  font-size: 0.95rem;
  //padding: 8px 11px;

}

.governmentSectionNavBarActive {
  background-color: rgba(255, 255, 255, 0.1);
  color: white !important;
  text-decoration: underline !important;
}
