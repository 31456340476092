


@media (max-width: 350px) {
  .issues-summary-header i {
    font-size: 22px !important;
  }
  .issues-summary-header h3 {
    font-size: 22px !important;
  }
}
