
/* new homepage redesign */


.star-spangled-banner-background {
    width: 100%;
    height: 25px;
    padding-top: 0px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    /*
    background-image:
      linear-gradient(
          rgba(1, 40, 103, 0.6),
          rgba(0, 0, 0, 0.5)),
      url(/static/images/homepage/donate-bg.png);

    */

    background-image: url(/static/images/general/star-spangled-banner-image-cropped.png);
    background-size: 150%;
    background-repeat: no-repeat
  }





.main-header-ispy-wrapper {
  position: relative;
}





  .main-header-ispy-wrapper:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.45;
    background-image: url('/static/images/general/capitol-background.png');
    background-repeat: no-repeat;
    background-position: none !important;
    background-size: 175%;

}



@media (min-width: 581px) {
  .main-header-ispy-wrapper:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.45;
    background-image: url('/static/images/general/capitol-background.png');
    background-repeat: no-repeat;
    background-position: 0% 25% !important;
    background-size: 175%;

}
}






.new-footer-button-background-img {
  background: url(/static/images/general/btn-star-spangles-3-resized-40.png);
  //background-size: 12%;
  //background-position: 0px -852px;
  background-repeat: no-repeat;
  font-size: 1rem;
  font-weight: 700;
  padding-left: 3rem !important;
}




.iSpyWrapperSpanLine {
    margin-bottom: 10px;
    position: absolute;
    display: block;
    height: 2px;
    width: 100%;
    background: red;
}


.iSpy-subheader-the-facts {
  font-weight: 700;
  color: #002664;
  margin-bottom: 0px;
  margin-top: 0px;
}


@media (min-width: 1077px) {
  .iSpy-subheader-the-facts {
    margin-top: 20px !important;
  }
}


.the-facts-arrow-rotated {
  display: none !important;
}


.the-facts-arrow-down {
    display: none !important;
  }

@media (max-width: 1199px) {

  .the-facts-arrow-rotated {
    display: unset !important;
    color: red;
    font-size: 4rem;
  }


  .the-facts-arrow-right {
    display: none !important;
  }


  .the-facts-arrow-down {
    display: unset !important;
  }


}



/* carousel start */

.homepage-carousel-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    background-image:
      linear-gradient(
          rgba(1, 40, 103, 0.6),
          rgba(0, 0, 0, 0.5)),
      url(/static/images/homepage/donate-bg.png);
    background-size: cover;
  }


.homepage-carousel {
    position: relative;
    padding: 0.25rem;
    margin: 0.25rem -15px;
}

@media (max-width:750px) {
    .homepage-carousel {
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
      border-width: .2rem
    }
  }


.homepage-carousel-header > a {
  color: #fff;
  text-decoration: none;
  font-weight: 700;
}


//@media (max-width: 550px) {
@media (max-width: 768px) {

  .homepage-carousel-header {
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.5rem !important;
  }

}


// testing fixed height here to prevent text wrapping moving carousel up and down (this does result in limitation on text length for the header)
.carousel-header-col {
  height: 154px;
  padding-right: 1em;
  padding-left: 1em;
}

@media (max-width: 550px) {
  .carousel-header-col {
    height: 120px;
    padding-right: 1em;
    padding-left: 1em;
  }
}


.tools-btn-link {
  padding: 0px !important;
}




// FOR HELP US POP UP

// overriding some of the default Bootstrap modal behavior here
.modal {
  overflow-y: hidden !important;
}

.modal-open {
  overflow-y: auto !important; // want users to be able to freely scroll page behind modal
  padding-right: 0px !important; // nix the weird shift-to-left thing that happens
}


.help-us-pop-up {
  background-color: #ff0d0c;
}

// stars as pseudo element so we can adjust opacity
.help-us-pop-up::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: url(/static/images/general/outline_star_black_18dp.png);
  background-repeat: repeat;
  opacity: 0.10;
}

.help-us-pop-up-modal-title {
  color: white;
  text-shadow: 2px 2px 3px #000000;
  font-family: "Roboto Slab", "Times New Roman", serif;
  font-weight: 700;
  z-index: 1;
}




.pre-election-help-us-pop-up-modal-content {


    background-color: rgb(32, 189, 240);
    position: relative;
    border-radius: 50% !important;
    /*width: 100%;*/
    /*width: 50vw;*/
    width: 400px;
    /*height: 50vw;*/
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
   /* max-height: 600px;*/

}





// FOR NEW HELP US POP UP MODAL AS PER 0009958


.helpus-pop-up-1 {
    background-image: url(/static/images/general/helpus-popup/VS-Lightbox6_test.jpg);
    background-size: cover;
}


.helpus-pop-up-top-right-text {
  position: absolute;
 top: 8px;
 right: 16px;
}






// exposed candidate pop up, overrding a bunch of BS modal stuff
/*
.help-us-exposed-candidate-modal-dialog {
  max-width: 400px !important;
  margin-top: 75px !important;
}



.help-us-exposed-candidate-modal-text-content {
  font-size: 35px;
  color: white !important;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 25px;
}

.help-us-exposed-candidate-modal-text-content-next {
  padding-top: 0.75rem !important;
}


.help-us-exposed-candidate-modal-content {
  border-radius: 50% !important;
  width: 400px !important;
  height: 400px !important;
}


.help-us-exposed-candidate-modal-text-content-col {
  margin-top: 1.5rem !important;
  margin-left: 0.0rem !important;
  padding-left: 0px;
  padding-right: 0px;

  p:nth-child(1) {
    padding-left: 1.25rem;
  }
  p:nth-child(2) {
    padding-left: 0.85rem;
  }
  p:nth-child(3) {
    padding-left: 0.65rem;
  }
}


.help-us-exposed-candidate-modal-text-content-row {
  margin-top: 1.5rem !important
}

.help-us-exposed-candidate-modal-body {
  border-radius: 50% !important;
  background-color: rgb(32, 189, 240) !important;
  width: 400px !important;
  height: 400px !important;
}

.help-us-exposed-candidate-modal-help-us-btn-row {
  margin-top: 1rem !important;
}

.help-us-exposed-candidate-modal-help-us-btn-col {
  margin-left: 1rem !important;
}


.help-us-exposed-candidate-modal-help-us-btn {
  font-weight: 700;
  padding: 12px 12px;
  font-size: 18px;
}



.help-us-exposed-candidate-modal-no-thx-btn-col button {
  color: white !important;
  text-decoration: underline;
  font-size: 18px;
  margin: 0px;
  padding: 0px;
}



.help-us-exposed-candidate-modal-text-content-hr {
  width: 55% !important;
  color: white !important;
  border-top: 1px solid white !important;
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: white !important;
  margin-left: 3.8rem !important;
}



@media (max-width: 576px) {
  // exposed candidate pop up
  .help-us-exposed-candidate-modal-dialog {
    margin-top: 1.75rem;
    margin-right: auto;
    margin-bottom: 1.75rem;
    margin-left: auto;
  }
}


@media (max-width: 435px) {

  // exposed candidate pop up
  .help-us-exposed-candidate-modal-dialog {
    max-width: 350px !important;
    //margin-top: 75px;
    margin-top: 1.75rem;
    margin-right: auto;
    margin-bottom: 1.75rem;
    margin-left: auto;
  }


  .help-us-exposed-candidate-modal-content {
    width: 350px !important;
    height: 350px !important;
  }


  .help-us-exposed-candidate-modal-body {
    width: 350px !important;
    height: 350px !important;
  }


  .help-us-exposed-candidate-modal-text-content {
    font-size: 32px !important;
  }



  .help-us-exposed-candidate-modal-help-us-btn-row{
    margin-top: 0.5rem !important;
  }

  .help-us-exposed-candidate-modal-hel-us-btn-col {
    margin-left: 0.5rem !important;
  }





  .help-us-exposed-candidate-modal-text-content-col {
    margin-top: 1.5rem !important;
    margin-left: 0.0rem !important;
    padding-left: 0px;
    padding-right: 0px;

    p:nth-child(1) {
      padding-left: 0.3rem;
    }
    p:nth-child(2) {
      padding-left: 0.15rem;
    }
    p:nth-child(3) {
      padding-left: 0.05rem;
    }
  }


  .help-us-exposed-candidate-modal-text-content-hr {
    width: 60% !important;
    color: white !important;
    border-top: 1px solid white !important;
    border-top-width: 1px !important;
    border-top-style: solid !important;
    border-top-color: white !important;
    margin-left: 2.8rem !important;
  }



}





@media (max-width: 415px) {

  // exposed candidate pop up
  .help-us-exposed-candidate-modal-dialog {
    max-width: 350px !important;
    //margin-top: 75px;
    margin-top: 1.75rem;
    margin-right: auto;
    margin-bottom: 1.75rem;
    margin-left: auto;
  }


  .help-us-exposed-candidate-modal-content {
    width: 350px !important;
    height: 350px !important;
  }


  .help-us-exposed-candidate-modal-body {
    width: 350px !important;
    height: 350px !important;
  }


  .help-us-exposed-candidate-modal-text-content {
    font-size: 30px !important;
  }



  .help-us-exposed-candidate-modal-help-us-btn-row{
    margin-top: 0.5rem !important;
  }

  .help-us-exposed-candidate-modal-hel-us-btn-col {
    margin-left: 0.5rem !important;
  }





  .help-us-exposed-candidate-modal-text-content-col {
    margin-top: 1.5rem !important;
    margin-left: 0.0rem !important;
    padding-left: 0px;
    padding-right: 0px;

    p:nth-child(1) {
      padding-left: 0.3rem;
    }
    p:nth-child(2) {
      padding-left: 0.15rem;
    }
    p:nth-child(3) {
      padding-left: 0.05rem;
    }
  }


  .help-us-exposed-candidate-modal-text-content-hr {
    width: 60% !important;
    color: white !important;
    border-top: 1px solid white !important;
    border-top-width: 1px !important;
    border-top-style: solid !important;
    border-top-color: white !important;
    margin-left: 2.8rem !important;
  }



}





@media (max-width: 385px) {
  // exposed candidate pop up
  .help-us-exposed-candidate-modal-dialog {
    max-width: 325px !important;
    //margin-top: 75px;
    margin-top: 0.05rem !important;
    margin-right: auto;
    margin-bottom: 1.75rem;
    margin-left: auto;
  }


  .help-us-exposed-candidate-modal-content {
    width: 325px !important;
    height: 325px !important;
  }


  .help-us-exposed-candidate-modal-body {
    width: 325px !important;
    height: 325px !important;
  }


  .help-us-exposed-candidate-modal-text-content {
    font-size: 28px !important;
    color: white !important;
    font-weight: 700;
    margin-bottom: 0px;
    line-height: 25px;
  }

  .help-us-exposed-candidate-modal-text-content-next {
    padding-top: 0.25rem !important;
  }


  .help-us-exposed-candidate-modal-text-content-row {
    margin-top: 0.25rem !important;
  }


  .help-us-exposed-candidate-modal-help-us-btn {
    font-weight: 700;
    padding: 12px 12px;
    font-size: 14px !important;
  }


  .help-us-exposed-candidate-modal-text-content-hr {
    width: 55% !important;
    color: white !important;
    border-top: 1px solid white !important;
    border-top-width: 1px !important;
    border-top-style: solid !important;
    border-top-color: white !important;
    margin-left: 2.8rem !important;
  }


  .help-us-exposed-candidate-modal-no-thx-btn-col button {
    font-size: 16px !important;
  }


}


@media (max-width: 345px) {
  // exposed candidate pop up
  .help-us-exposed-candidate-modal-dialog {
    max-width: 300px !important;
    //margin-top: 75px;
    margin-top: 1.75rem;
    margin-right: auto;
    margin-bottom: 1.75rem;
    margin-left: auto;
  }


  .help-us-exposed-candidate-modal-content {
    width: 300px !important;
    height: 300px !important;
  }


  .help-us-exposed-candidate-modal-body {
    width: 300px !important;
    height: 300px !important;
  }


  .help-us-exposed-candidate-modal-text-content {
    font-size: 25px !important;
    color: white !important;
    font-weight: 700;
    margin-bottom: 0px;
    line-height: 25px;
  }

  .help-us-exposed-candidate-modal-text-content-next {
    padding-top: 0.25rem !important;
  }


  .help-us-exposed-candidate-modal-text-content-row {
    margin-top: 0.5rem !important;
  }


}




@media (max-width: 325px) {
  // exposed candidate pop up
  .help-us-exposed-candidate-modal-dialog {
    max-width: 280px !important;
    //margin-top: 75px;
    margin-top: 1.75rem;
    margin-right: auto;
    margin-bottom: 1.75rem;
    margin-left: auto;
  }


  .help-us-exposed-candidate-modal-content {
    width: 280px !important;
    height: 280px !important;
  }


  .help-us-exposed-candidate-modal-body {
    width: 280px !important;
    height: 280px !important;
  }


  .help-us-exposed-candidate-modal-text-content {
    font-size: 25px !important;
    color: white !important;
    font-weight: 700;
    margin-bottom: 0px;
    line-height: 25px;
  }

  .help-us-exposed-candidate-modal-text-content-next {
    padding-top: 0.25rem !important;
  }


  .help-us-exposed-candidate-modal-text-content-row {
    margin-top: 0.5rem !important;
  }




  .help-us-exposed-candidate-modal-text-content-hr {
    width: 55% !important;
    color: white !important;
    border-top: 1px solid white !important;
    border-top-width: 1px !important;
    border-top-style: solid !important;
    border-top-color: white !important;
    margin-left: 2.8rem !important;
    margin-bottom: 0.5rem !important;
  }

  .help-us-exposed-candidate-modal-help-us-btn-row {
    margin-top: 0.1rem !important;
  }

  .help-us-exposed-candidate-modal-help-us-btn-col {
    margin-left: 0.5rem !important;
  }


}


@media (max-width: 320px) {
  .help-us-exposed-candidate-modal-text-content {
    font-size: 24px !important;
  }
}



@media (orientation: landscape) {


  .help-us-exposed-candidate-modal-dialog {
    margin-top: 10px !important;
  }

  @media (max-height: 415px) {

    // exposed candidate pop up
    .help-us-exposed-candidate-modal-dialog {
      max-width: 350px !important;
      //margin-top: 75px;
      margin-top: 1.75rem;
      margin-right: auto;
      margin-bottom: 1.75rem;
      margin-left: auto;
    }


    .help-us-exposed-candidate-modal-content {
      width: 350px !important;
      height: 350px !important;
    }


    .help-us-exposed-candidate-modal-body {
      width: 350px !important;
      height: 350px !important;
    }


    .help-us-exposed-candidate-modal-text-content {
      font-size: 30px !important;
    }



    .help-us-exposed-candidate-modal-help-us-btn-row{
      margin-top: 0.5rem !important;
    }

    .help-us-exposed-candidate-modal-hel-us-btn-col {
      margin-left: 0.5rem !important;
    }


    .help-us-exposed-candidate-modal-text-content-col {
      margin-top: 1.5rem !important;
      margin-left: 0.0rem !important;
      padding-left: 0px;
      padding-right: 0px;

      p:nth-child(1) {
        padding-left: 0.3rem;
      }
      p:nth-child(2) {
        padding-left: 0.15rem;
      }
      p:nth-child(3) {
        padding-left: 0.05rem;
      }
    }


    .help-us-exposed-candidate-modal-text-content-hr {
      width: 60% !important;
      color: white !important;
      border-top: 1px solid white !important;
      border-top-width: 1px !important;
      border-top-style: solid !important;
      border-top-color: white !important;
      margin-left: 2.8rem !important;
    }

  }




  @media (max-height: 325px) {

    // exposed candidate pop up
    .help-us-exposed-candidate-modal-dialog {
      max-width: 300px !important;
      //margin-top: 75px;
      margin-top: 1.75rem;
      margin-right: auto;
      margin-bottom: 1.75rem;
      margin-left: auto;
    }


    .help-us-exposed-candidate-modal-content {
      width: 300px !important;
      height: 300px !important;
    }


    .help-us-exposed-candidate-modal-body {
      width: 300px !important;
      height: 300px !important;
    }


    .help-us-exposed-candidate-modal-text-content {
      font-size: 25px !important;
    }



    .help-us-exposed-candidate-modal-help-us-btn-row{
      margin-top: 0.5rem !important;
    }

    .help-us-exposed-candidate-modal-help-us-btn-col {
      margin-left: 0.5rem !important;
    }


    .help-us-exposed-candidate-modal-text-content-col {
      margin-top: 0.25rem !important;
      margin-left: 0.0rem !important;
      padding-left: 0px;
      padding-right: 0px;

      p:nth-child(1) {
        padding-left: 0.3rem;
      }
      p:nth-child(2) {
        padding-left: 0.15rem;
      }
      p:nth-child(3) {
        padding-left: 0.05rem;
      }
    }


    .help-us-exposed-candidate-modal-text-content-hr {
      width: 60% !important;
      color: white !important;
      border-top: 1px solid white !important;
      border-top-width: 1px !important;
      border-top-style: solid !important;
      border-top-color: white !important;
      margin-left: 2.8rem !important;
      margin-bottom: 5px !important;
    }


    .help-us-exposed-candidate-modal-help-us-btn {
      font-weight: 700;
      padding: 12px 12px;
      font-size: 14px;
    }


    .help-us-exposed-candidate-modal-no-thx-btn-col button {
      color: white !important;
      text-decoration: underline;
      font-size: 14px;
      margin: 0px;
      padding: 0px;
    }

    .help-us-exposed-candidate-modal-text-content-next {
      padding-top: 0.5rem !important;
    }

  }


}

*/
