.candidate-summary-badge {
  font-size: 14px !important;
}

#candidateSummaryDataTypeDropdown {
  span {
    position: absolute;
    display: inline-block;
    right: 10px;
    font-size: 16px;
    padding-left: 10px;
  }
}

.candidate-summary-data-type-dropdown {
    width: 200px !important;
}

#candidateSummaryDataTypeSubmitBtn {
  margin-left: 10px;
  font-weight: 700;
  display: block;
  width: 90%;
}

.summary-items-card-body {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.candidate-summary-type-col {
  i {
    padding-right: 0.5rem;
  }
}


.summary-filter-btn {
  font-weight: 700;
}

.summary-chosen-category-title {
  h4 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  hr {
    margin-top: 0px !important;
  }
}
