// elections base

.electionsSectionNavBar {
  background-color: #002664;
  overflow: auto;
  white-space: nowrap;
  padding: 0.25rem 0.5rem;
}

.electionsSectionNavBar a {
  display: inline-block;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  font-weight: 700;
  color: white;
  font-size: 0.95rem;
  //padding: 8px 11px;

}


.electionsSectionNavBarActive {
  background-color: rgba(255, 255, 255, 0.1);
  color: white !important;
  text-decoration: underline !important;
}



.elections-section-horizontal-nav {
  flex-wrap: nowrap !important;
}















// NAV




.pn-ProductNav_Wrapper {
	position: relative;
	padding: 0 11px;
  //padding: 0px;
	box-sizing: border-box;
}

.pn-ProductNav {
    /* Make this scrollable when needed */
    overflow-x: auto;
    /* We don't want vertical scrolling */
    overflow-y: hidden;
    /* For WebKit implementations, provide inertia scrolling */
    -webkit-overflow-scrolling: touch;
    /* We don't want internal inline elements to wrap */

    // for moz
    scrollbar-width: none;


    white-space: nowrap;
    /* If JS present, let's hide the default scrollbar */
    .js & {
        /* Make an auto-hiding scroller for the 3 people using a IE */
        -ms-overflow-style: -ms-autohiding-scrollbar;

        /* Remove the default scrollbar for WebKit implementations */
        &::-webkit-scrollbar {
            display: none;
        }

    }
	/* positioning context for advancers */
	position: relative;
	// Crush the whitespace here
	font-size: 0;
}

.pn-ProductNav_Contents {
	float: left;
  //float: none;
	transition: transform .2s ease-in-out;
	position: relative;
}

// center nav items at this breakpoint, need the float:left for the arrows to work
@media(min-width: 730px) {
  .pn-ProductNav_Contents {
    float: none;
  	transition: transform .2s ease-in-out;
  	position: relative;
  }
}




@media(max-width: 715px) {
  .pn-Advancer_Right {
    opacity: 1 !important;
  }
}

@media(min-width: 716px) {
  .pn-Advancer_Right {
    opacity: 0 !important;
  }
}



.pn-ProductNav_Contents-no-transition {
	transition: none;
}

.pn-ProductNav_Link {
	text-decoration: none;
	color: #888;
	// Reset the font size
	//font-size: 1.2rem;
  font-size: 0.95rem;
  font-weight: 700;
	font-family: -apple-system, sans-serif;
	display: inline-flex;
	align-items: center;
	min-height: 44px;
	border: 1px solid transparent;
	padding: 0 11px;
	& + & {
		border-left-color: #eee;
	}
	&[aria-selected="true"] {
		color: #111;
	}
}

.pn-Advancer {
	/* Reset the button */
	appearance: none;
	background: transparent;
	padding: 0;
	border: 0;
	&:focus {
		outline: 0;
	}
	&:hover {
		cursor: pointer;
	}
	/* Now style it as needed */
	position: absolute;
	top: 0;
	bottom: 0;
	/* Set the buttons invisible by default */
	opacity: 0;
	transition: opacity .3s;
}

.pn-Advancer_Left {
	left: 0;
	[data-overflowing="both"] ~ &,
	[data-overflowing="left"] ~ & {
		opacity: 1;
	}
}

.pn-Advancer_Right {
	right: 0;
	[data-overflowing="both"]  ~ &,
	[data-overflowing="right"] ~ & {
		opacity: 1;
	}
}

.pn-Advancer_Icon {
	width: 20px;
	height: 44px;
	fill: #bbb;
}

.pn-ProductNav_Indicator {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 4px;
	width: 100px;
	background-color: transparent;
	transform-origin: 0 0;
	transition: transform .2s ease-in-out, background-color .2s ease-in-out;
}

// NAV




// summary


#electionsSummaryDropdownContainer {
  display: none;
}

@media(max-width: 767px) {
  #electionsSummaryDropdownContainer {
    display: unset;
  }

  #electionsSummaryNavList {
    display: none;
  }

  .elections-card-tab-header {
    display: none;
  }

}



.elections-summary-menu-item {
   display: block;
   width: 100%;
   //padding: 0.625rem 1.25rem;
   clear: both;
   font-weight: 400;
   color: #212529;
   text-align: inherit;
   white-space: nowrap;
   background-color: transparent;
   border: 0;
   //font-size: 1rem;
   font-size: 12px;
 }


 .elections-summary-menu-item a {
   color: #555;
 }


 .elections-summary-menu-item:hover a, .elections-summary-menu-item:focus a, .elections-summary-menu-item:active a {
   -webkit-box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 38, 100, 0.4);
   box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 38, 100, 0.4);
   background-color: #002664;
   color: #FFFFFF !important;
 }





.returned-pct-meter {
  .background {
    fill: #E6E7E8;
  }
  .foreground {
    fill: #0d47a1;
  }
  text {
    &.percent-complete {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 25px;
      fill: #5B8BE3;
      letter-spacing: -.03em;
    }
    &.description {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 12px;
      fill: #9B9B9B;
      font-weight: 700;
    }
  }
}



.elections-summary-headers h4 {
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 0px;
}




.googleCalendar {
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 50%;
}

// might need queries for this for bottom margin
.googleCalendar iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 108%;
  padding-left: 15px;
  padding-right: 15px;
}


@media(min-width: 1200px) {
  .googleCalendar iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 106%;
    padding-left: 15px;
    padding-right: 15px;
  }
}



@media(max-width: 766px) {
  .googleCalendar {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
  }

  .googleCalendar iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 95%;
    padding-left: 15px;
    padding-right: 15px;
  }
}




.pctrr-header-title {
  color: #002664;
  text-decoration: none;
  font-weight: 700;
  margin-bottom: 0px;
}



// detail


@media(max-width: 991px) {
  #chartjsCanvasData {
    padding-top: 50px;
  }
}



@media(max-width: 380px) {
  .ballot-measure-card-body {
    padding: 0.9375rem 0.75rem !important;
  }
}

.elections-details-dropdowns {
    a {
      font-weight: 700;
    }

    h4 {
      margin: 0px;
    }
}

.ballot-measure-arguments a {
  color: #002664;
  text-decoration: underline;
}

.ballot-measure-arguments:hover a, .ballot-measure-arguments:focus a {
  color: #001c4b;
}



.election-details-candidate-name a {
    color: #8ebaff !important;
    font-weight: bold;
}

.election-details-candidate-name a:hover {
    opacity: 1;
}

#electionsDetailsResultsCol > h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

#electionsDetailsPctSearchCardBody > h3 {
  margin-bottom: 0px;
}

#electionsDetailsPctSearchCardBody > hr {
  margin-top: 0px;
}

@media(max-width: 415px) {

  #electionsDetailsCardBody, #electionsDetailsPctSearchCardBody {
    padding-left: .70rem !important;
    padding-right: .70rem !important;
  }

  .details-pct-search-card {
    div.card-body {
      padding: 1.25rem;
    }
  }

}


@media(max-width: 990px) {
  .election-details-search-btn {
    margin-top: 10px !important;
  }
}



// Contents
//
// Navbar
// Navbar brand
// Navbar nav
// Navbar text
// Navbar divider
// Responsive navbar
// Navbar position
// Navbar themes


// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.


.default-navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap; // allow us to do the line break for collapsing content
  align-items: center;
  justify-content: space-between; // space out brand from logo
  padding: $navbar-padding-y $navbar-padding-x;

  // Because flex properties aren't inherited, we need to redeclare these first
  // few properities so that content nested within behave properly.
  > .container,
  > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}


// Navbar brand
//
// Used for brand, project, or site names.

.default-navbar-brand {
  display: inline-block;
  padding-top: $navbar-brand-padding-y;
  padding-bottom: $navbar-brand-padding-y;
  margin-right: $navbar-padding-x;
  font-size: $navbar-brand-font-size;
  line-height: inherit;
  white-space: nowrap;

  @include hover-focus {
    text-decoration: none;
  }
}


// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.default-navbar-nav {
  display: flex;
  flex-direction: column; // cannot use `inherit` to get the `.default-navbar`s value
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .dropdown-menu {
    position: static;
    float: none;
  }
}


// Navbar text
//
//

.default-navbar-text {
  display: inline-block;
  padding-top: $nav-link-padding-y;
  padding-bottom: $nav-link-padding-y;
}


// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orienation. Requires the use of `flex-wrap: wrap`
// on the `.default-navbar` parent.
.default-navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  // For always expanded or extra full navbars, ensure content aligns itself
  // properly vertically. Can be easily overridden with flex utilities.
  align-items: center;

  font-size: .8rem !important;
}

// Button for toggling the navbar when in its collapsed state
.default-navbar-toggler {

  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  /*
  padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
  font-size: $navbar-toggler-font-size;
  line-height: 1;
  background-color: transparent; // remove default button style
  border: $border-width solid transparent; // remove default button style
  @include border-radius($navbar-toggler-border-radius);

  @include hover-focus {
    text-decoration: none;
  }
  */


  // Opinionated: add "hand" cursor to non-disabled .default-navbar-toggler elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}


// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.default-navbar-toggler-icon {
  /*
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  */


  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  background-color: #ffffff;
  position: relative;
  width: 22px;
  height: 2px;
  vertical-align: middle;
  outline: 0;
  display: block;
  border-radius: 1px;


}



// Generate series of `.default-navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.default-navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        > .container,
        > .container-fluid {
          //padding-right: 0;
          padding-right: 15px;
          //padding-left: 0;
          padding-left: 15px;
        }
      }

      @include media-breakpoint-up($next) {
        flex-flow: row nowrap;
        justify-content: flex-start;

        .default-navbar-nav {
          flex-direction: row;

          .dropdown-menu {
            position: absolute;
          }

          .dropdown-menu-right {
            right: 0;
            left: auto; // Reset the default from `.dropdown-menu`
          }

          .nav-link {
            padding-right: $navbar-nav-link-padding-x;
            padding-left: $navbar-nav-link-padding-x;
          }
        }

        // For nesting containers, have to redeclare for alignment purposes
        > .container,
        > .container-fluid {
          flex-wrap: nowrap;
        }

        .default-navbar-collapse {
          display: flex !important;  // stylelint-disable-line declaration-no-important

          // Changes flex-bases to auto because of an IE10 bug
          flex-basis: auto;
        }


        .default-navbar-toggler {
          display: none;
        }


        .default-navbar-toggler {
          display: none;
        }

        .dropup {
          .dropdown-menu {
            top: auto;
            bottom: 100%;
          }
        }
      }
    }
  }
}


// Navbar themes
//
// Styles for switching between navbars with light or dark background.





// Dark links against a light background
.default-navbar-light {

  .default-navbar-brand {
    color: $navbar-light-active-color;

    @include hover-focus {
      color: $navbar-light-active-color;
    }
  }

  .default-navbar-nav {
    .nav-link {
      color: $navbar-light-color;

      @include hover-focus {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }


  .default-navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }




  .default-navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }




  .default-navbar-text {
    color: $navbar-light-color;
    a {
      color: $navbar-light-active-color;

      @include hover-focus {
        color: $navbar-light-active-color;
      }
    }
  }
}

// White links against a dark background
.default-navbar-dark {
  .default-navbar-brand {
    color: $navbar-dark-active-color;

    @include hover-focus {
      color: $navbar-dark-active-color;
    }
  }

  .default-navbar-nav {
    .nav-link {
      color: $navbar-dark-color;

      @include hover-focus {
        color: $navbar-dark-hover-color;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }


  .default-navbar-toggler {
    color: $navbar-dark-color;
    border-color: $navbar-dark-toggler-border-color;
  }


  .default-navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }


  .default-navbar-text {
    color: $navbar-dark-color;
    a {
      color: $navbar-dark-active-color;

      @include hover-focus {
        color: $navbar-dark-active-color;
      }
    }
  }
}




.default-navbar-bg-primary {

  color: #fff;
  background-color: $blue-vs;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 12px -5px rgba(156,39,176,.46)

}





.elections-ballot-measures-table th {
  font-weight: 700 !important;
}


/* measures summary */

@media(max-width: 530px) {

  #electionsBallotMeasuresTable {
    display: none !important;
  }

  #electionsBallotMeasuresAccordion {
    display: unset !important;
  }
  #electionsMeasuresAccordionExpandAll {
    display: unset !important;
  }

}


.measures-result-list {
  list-style-type: none !important;
  padding-inline-start: 0px !important;
}


/* measure details */

.measures-circle {
  width: 200px;
  height: 200px;
  //line-height: 200px;
  border-radius: 50%; /* the magic */
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 auto 40px;
  box-shadow: 0 7px 34px -4px rgba(0,0,0,0.6),0 16px 12px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 7px 34px -4px rgba(0, 0, 0, 0.6), 0 16px 12px rgba(0, 0, 0, 0.1);
  padding-top: 10px;
}


@media(max-width: 350px) {
  .measures-circle {
    width: 175px;
    height: 175px;
  }

  .measures-detail-circle-content {
    a {
      font-size: .7rem;
    }

    h3 {
      font-size: 1.50rem;
    }
  }

}

.green {
  background-color: #008000;
}

.red {
  background-color: #ff0000;
}


/*
.blue {
  background-color: #3498db;
}

.red {
  background-color: #e74c3c;
}
*/

.measures-detail-circle-content h3 {
  margin-top: 0px !important;
  margin-bottom: 15px !important;
  color: white !important;
}


.measures-content {
  h4 {
    margin-bottom: 0px !important;
  }

  hr {
    margin-top: 0px !important;
  }

  a {
    font-weight: 700;
  }
}

.measure-detail-intro-section {
  margin-bottom: 10px !important;
}



@media(max-width: 992px) {
  #measureDetailDoughnutHr {
    opacity: 0;
  }
}


// offices
.elections-addresses {
  font-size: 1rem;
}

.elections-addresses hr {
  margin-top: 0px;
}

.elections-addresses i {
  padding-right: 0.7rem !important;
}


.elections-offices-card-body h4 {
  margin-bottom: 0px;
  margin-top: 0px;
  color: #002664;
}

.elections-offices-card {
  text-align: left;
  margin: 0px;
}


// using material palette https://www.materialpalette.com/
.electionstatus-deceased {
  color: black !important;
}

.electionstatus-lost {
  color: #ff1b0a !important;
}

.electionstatus-potential {
  color: #03A9F4 !important;
}

.electionstatus-removed {
  color: #9E9E9E !important;
}

.electionstatus-running {
  color: #9C27B0 !important;
}

.electionstatus-too-close-to-call {
  color: #009688 !important;
}

.electionstatus-withdrawn {
  color: #ff9e0f !important;
}

.electionstatus-won {
  color: #4CAF50 !important;
}

.electionstatus-announced {
  color: #e91e63 !important;
}

.electionstatus-campaign-suspended {
  color: #FF5722 !important;
}

.electionstatus-default {
  color: black !important;
}
