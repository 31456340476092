
.ratingdetail-dropdown-menu-btn {
  font-size: .85rem !important;
}




.table-fixed tbody {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}

.table-fixed thead,
th {
  //background: #002664;
}

.table-fixed thead > tr > th {
  //color: white;
  color: #002664;
  font-weight: 700;
}

.table-fixed tbody,
.table-fixed td,
.table-fixed th,
.table-fixed thead,
.table-fixed tr {
  display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
  float: left;
  position: relative;

  &::after {
    content: '';
    clear: both;
    display: block;
  }
}
// FLIPPED ACCESS AND OVERFLOW
#flipToScrollTable {
  .rtable {
    /*!
    // IE needs inline-block to position scrolling shadows otherwise use:
    // display: block;
    // max-width: min-content;
    */
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    overflow-x: auto;
    // optional - looks better for small cell values
    white-space: nowrap;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .rtable,
  .rtable--flip tbody {
    // optional - enable iOS momentum scrolling
    -webkit-overflow-scrolling: touch;
    // scrolling shadows
    background: radial-gradient(left, ellipse, rgba(0,0,0, .2) 0%, rgba(0,0,0, 0) 75%) 0 center, radial-gradient(right, ellipse, rgba(0,0,0, .2) 0%, rgba(0,0,0, 0) 75%) 100% center;
    background-size: 10px 100%, 10px 100%;
    background-attachment: scroll, scroll;
    background-repeat: no-repeat;
  }
  // change these gradients from white to your background colour if it differs
  // gradient on the first cells to hide the left shadow
  .rtable td:first-child,
  .rtable--flip tbody tr:first-child {
    background-image: linear-gradient(to right, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-repeat: no-repeat;
    background-size: 20px 100%;
  }
  // gradient on the last cells to hide the right shadow
  .rtable td:last-child,
  .rtable--flip tbody tr:last-child {
    background-image: linear-gradient(to left, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 20px 100%;
  }

  .rtable th {
    font-size: 11px;
    text-align: left;
    text-transform: uppercase;
    background: #002664;
    color: white;
  }

  .rtable td,
  .rtable th {
    padding: 6px 12px;
    border: 1px solid #d9d7ce;
  }

  .rtable--flip {
    display: flex;
    overflow: hidden;
    background: none;
  }

  .rtable--flip thead {
    display: flex;
    flex-shrink: 0;
    min-width: min-content;
  }

  .rtable--flip tbody {
    display: flex;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .rtable--flip tr {
    display: flex;
    flex-direction: column;
    min-width: min-content;
    flex-shrink: 0;
  }

  .rtable--flip td,
  .rtable--flip th {
    display: block;
  }

  .rtable--flip td {
    background-image: none !important;
    // border-collapse is no longer active
    border-left: 0;
  }
  // border-collapse is no longer active
  .rtable--flip td:not(:last-child),
  .rtable--flip th:not(:last-child) {
    border-bottom: 0;
  }

  table {
    margin-bottom: 30px;
  }
}




.rating-detail-table-header-row > th > a:hover {
  color: #797EE5;
}

/*
Generic Styling, for Desktops/Laptops
*/
#transformedTable {
  //new
  th {
    color: #002664;
    font-weight: bold;
    font-size: 1rem;
  }

  td {
    font-size: 1rem;
  }
  /*

  table {
    width: 100%;
    border-collapse: collapse;
  }

  // Zebra striping
  //tr:nth-of-type(odd) {
  //  background: #eee;
  //}


  th {
    //background: #002664;
    //color: white;
    color: #002664;
    font-weight: bold;
    font-size: 1rem;
  }
  td, th {
    padding: 6px;
    //border: 1px solid #ccc;
    text-align: left;
  }
  */
}


.rating-details-dropdown-sort-menu {
  display: none;
}





@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {


  .rating-details-dropdown-sort-menu {
    display: unset;
  }


  #transformedTable {
    /* Force table to not be like tables anymore */
    table,
    tbody,
    td,
    th,
    thead,
    tr {
      display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      font-size: 1rem;
      height: 49px; // new
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      color: #002664;
    }
    /*
	Label the data
	*/
    td:nth-of-type(1):before {
      content: "State";
      font-weight: 700;
      padding-top: 6px;
    }

    td:nth-of-type(2):before {
      content: "Office";
      font-weight: 700;
      padding-top: 6px;
    }

    td:nth-of-type(3):before {
      content: "District";
      font-weight: 700;
      padding-top: 6px;
    }

    td:nth-of-type(4):before {
      content: "Name";
      font-weight: 700;
      padding-top: 6px;
    }

    td:nth-of-type(5):before {
      content: "Party";
      font-weight: 700;
      padding-top: 6px;
    }

    td:nth-of-type(6):before {
      content: "Rating";
      font-weight: 700;
      padding-top: 6px;
    }
  }
}




.ratings-detail-header-link {
  text-decoration: underline !important;
}


.sig-list-header-row th {
  font-weight: 700 !important;
  font-size: 1rem !important;
}

.sig-list-tbody tr td {
  font-size: 1rem !important;
}




.interest-group-ratings-table {
  font-weight: 400;
}


#truncatedRatingDetailTable {
  display: none;
}

/*
.truncated-rating-table > thead > tr > th {
  padding: 12px 4px !important;
}
*/

@media (max-width: 500px) {

  #truncatedRatingDetailTable {
    display: unset;
  }



  #mainRatingDetailTable {
    display: none;
  }
}
