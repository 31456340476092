
.accordion-header-font > b {
  font-size: 1.25rem !important;
}

.accordion-card {
  margin-top: 0px !important;
}

.candidate-header-text > p {
  margin-bottom: 0px !important;
}

.candidate-text > a {
  font-size: 18px !important;
}

#trackPoliticianBtn {
  font-size: 12px;
}

.bull-logo-img {
  max-width: 75px !important;
  padding-right: 5px !important;
  //@include shadow-6dp();

}

.bull-logo-img-table {
  max-width: 50px !important;
  padding-right: 5px !important;
}



.bull-badge {
  font-size: 14px;
}

.bull-info-icon {
  color: #797EE5 !important;
  position: relative;
  bottom: 20px;
  left: 10px;
}


.bull-card {
  max-width: 12rem;
  margin-top: 0px;
}

@media (max-width: 767px) {
  .bull-card {
    margin-top: 10px;
  }
}


.category-links-material-icons {
  font-size: 1.75rem !important;
}


.category-links-material-icons-left {
  padding-right: 10px;
}









/*
.fifth {
  //border-color: #8e44ad;
  border-color: #e91e63;
  border-radius: 0;
  //color: #8e44ad;
  color: #e91e63;
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: color 250ms ease-in-out;
  transition: color 250ms ease-in-out;
}


.fifth::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0;
  height: 100%;
  //background: #8e44ad;
  background: #e91e63;
  z-index: -1;
  -webkit-transition: width 250ms ease-in-out;
  transition: width 250ms ease-in-out;
}


.fifth:hover {
  color: #fff !important;
}
.fifth:hover:after {
  width: 110%;
}
*/



.cta-candidate-more-info-btn {
  //color: #e91e63;
  color: #fff !important;
  //background-color: transparent;
  background-color: #e91e63 !important;
  border-color: #e91e63 !important;
  border-style: solid;
  border-width: 1px;
  white-space: normal;
}

.cta-candidate-more-info-btn-icon {
  font-size: 2rem;
  margin-top: -1.2rem;
  margin-right: 0.15rem;
}

/*
.cta-btn-outline:hover {
  background-color: #e91e63 !important;
}
*/

.btn-candidate-more-info {
  font-weight: 700;
  padding: 1.125rem 0.25rem;
  font-size: 18px;
  color: #e91e63 !important;
  border-color: #e91e63 !important;
  border-width: 2px !important;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-breal: break-all !important;
  -webkit-hyphens: auto !important;
  -ms-hyphens: auto !important;
  -moz-hyphens: auto !important;
  hyphens: auto !important;
  white-space: normal;

}




#candidateMoreInfoRowMobile {
  display: none;
}

@media(max-width: 775px) {
  #candidateMoreInfoRowMobile {
    display: unset;
  }

  #candidateMoreInfoRowDesktop {
    display: none;
  }
}
