.statments-category-icons {
	padding-left: 10px !important;
	padding-right: 10px !important;
}


@media (max-width: 319px) and (min-width: 290px){
	.category-links-wrapper {
		padding-right: 0px !important;
		padding-left: 0px !important;
	}
}

.statements-date-range-header {
	margin-bottom: 0px !important;
}

.statements-date-range-header-hr {
	margin-top: 0px !important;
}


@media (max-width: 767px) {
	#statementsKeywordSearchSubmitBtn {
		margin-top: 17px;
	}
}

.search-field-input-feedback {
	  position: absolute;
    top: 8px;
    right: 0;
    z-index: 2;
    display: block;
    //width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
    /* opacity: 0; */
}

.search-field-input-feedback-clear {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-width: 0px !important;
}


::-ms-clear {
  display: none;
}

.form-control-clear {
  z-index: 10;
  pointer-events: auto;
  cursor: pointer;
}


#statementsFilterForm {
	.btn {
	 font-weight: 700;
	}
}



.statements-table-header {
	>thead {
		>tr {
			>th {
				color: $table_header_color_1 !important;
				font-weight: 700 !important;
			}
		}
	}
}


.statements-table {
	td, th {
		font-size: 16px !important;
	}
}


@media (max-width: 400px) {
	.statements-table {
		td, th {
			font-size: 14px !important;
		}
  }
}
