// multilevel dropdown

.dropdown {
  .dropdown-menu {
    .dropdown-item.dropdown-toggle + .dropdown-menu {
      transform: scale(0);
      transform-origin: 0 0;
      display: block;
    }
  }
  .dropdown-menu.show {
    .dropdown-item.dropdown-toggle + .dropdown-menu.show {
      left: 101% !important;
      transform: scale(1);
    }

    .dropdown-toggle.open:after {
      transform: rotate(0deg);
    }
  }
}

// Navbar Logo

.navbar {
  &.navbar-transparent {
    .logo-big {
       width: 150px;
       height: 50px;
       overflow: hidden;
       margin-top: -10px;
       display: block;

       img {
         height: 100%;
       }
    }
    .logo-small {
      display: none;
    }
  }

  &:not(.navbar-transparent) {
    .logo-big {
      display: none;
    }

    .logo-small {
      width: 46px;
      height: 46px;
      overflow: hidden;
      margin-top: -6px;
      display: block;
    }
  }
}


// section page - responsive (iPhone 5)

@media all and (max-width: 375px) {
  .page-header {
    height: calc(100vh + 270px);
  }

  .media-area .media-body {
    width: 190px;

    .media,
    .media.media-post {
      margin-left: -50px;
    }
  }

  .index-page {
    overflow-x: hidden;
  }

  #social-buttons {
    .social-buttons-demo:last-of-type {
      margin-left: -25px;
    }
  }
  .pagination {
    &.pagination-primary {
      .page-item:nth-of-type(3),
      .page-item:nth-of-type(7) {
        display: none;
      }
    }
    &.pagination-info {
      .page-item:nth-of-type(2) {
        display: none;
      }
    }
  }

  .main.main-raised {
    margin-top: -30px;
  }
}


// documentation

.bd-docs .bd-example {
  .social-buttons-demo .btn{
    margin-bottom: 10px;
  }

  &.bd-example-popover-static .popover,
  &.bd-example-tooltip-static .tooltip {
    z-index: 3 !important;
  }

  .alert-dismissible .close {
    top: 7px;
  }
}
