@media (max-width: 415px) {

  .officials-card-item {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  #chartjsCanvasData {
    margin-top: 0px !important;
  }

}




// overwriting .container so cards media divs in col look better
/*
@media (min-width: 576px) {

  .officials-container {
    max-width: unset !important;
  }
}
*/





















.officials-parent-container {
-webkit-box-sizing: border-box;
        box-sizing: border-box;
}


/* ---- grid ---- */

.officials-grid {
background: #EEE;
}

/* clearfix */
.officials-grid:after {
content: '';
display: block;
clear: both;
}

/* ---- grid-item ---- */

.officials-grid-item {
//width: 120px;
//height: 120px;
width: 290px;
height: 175px;
float: left;
background: #D26;
border: 2px solid #333;
border-color: hsla(0, 0%, 0%, 0.5);
border-radius: 5px;
margin: 5px;
}

.officials-grid-item--width2 { width: 580px; }

.officials-grid-item--height2 { height: 580px; }





/* customizing BS card-columns & card classes */
.card-columns {
  @include media-breakpoint-only(xl) {
    column-count: 4 !important;
  }
  @include media-breakpoint-only(lg) {
    column-count: 3 !important;
  }
  @include media-breakpoint-only(md) {
    column-count: 2 !important;
  }
  @include media-breakpoint-only(sm) {
    column-count: 1 !important;
  }
}







/* ---- grid ---- */

.new-officials-grid {
  background: #DDD;
  box-sizing: border-box;
}

/* clear fix */
.new-officials-grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.new-officials-grid-sizer,
.new-officials-grid-item {
  width: 33.333%;
}

.new-officials-grid-item {
  float: left;
}

.new-officials-grid-item img {
  display: block;
  max-width: 100%;
}






.bricklayer{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:start;-webkit-align-items:flex-start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap}.bricklayer-column-sizer{width:100%;display:none}@media screen and (min-width:640px){.bricklayer-column-sizer{width:50%}}@media screen and (min-width:980px){.bricklayer-column-sizer{width:33.333%}}@media screen and (min-width:1200px){.bricklayer-column-sizer{width:25%}}.bricklayer-column{-webkit-box-flex:1;-webkit-flex:1;-ms-flex:1;flex:1;padding-left:5px;padding-right:5px}
