.contact-type-headers {
  font-size: 1.25rem !important;
}

.office-address-type {
  display: none;
}

.candidate-bio-phone {
  font-size: 18px !important;
}

.fa-fax,
.fa-phone,
.fa-tty {
  padding-right: 1rem !important;
}

.icon-text {
  vertical-align: middle;
  padding-left: 0.25rem;
}

.btn-candidate-bio-info {
  font-size: 0.85rem !important;
}

.contacts-collapse-btn {
  font-size: 15px !important;
  font-weight: bold;
}

.candidate-office-dropdown-item {
  cursor: pointer;
  font-size: 15px !important;
  font-weight: bold;
}

.contact-info-accordion-card {
  margin-top: 0 !important;
}


#trackPoliticianBtn {
  font-weight: 700;
}
