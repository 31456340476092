//$width: 100px;
$width: 95px;
$angle: -45deg; // folder opening
$folder-font: $blue-vs;
$folder-font-size: 17px;
@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

label {
	cursor: pointer;
	/*
	Chrome bug that resulted in blurry text when using this
	prop to mitigate issues with transform (i.e. other elements unexpectedly
	affected by in-focuse element transform) seems to have gone away as of
	Chrome Version 75.0.3770.80 (Official Build) (64-bit), keeping note here
	in case reported again.
	*/
	//-webkit-backface-visibility: hidden !important;
}

/* start of flex box folder queries */
.folder-flex-wrapper {}

/*

@media (min-width: 480px) and (max-width: 528px) {
	.folder-flex-wrapper {
		margin-right: 60px;
	}
}
@media (min-width: 389px) and (max-width: 479px) {
	.folder-flex-wrapper {
		margin-right: 30px;
	}
}
@media (min-width: 370px) and (max-width: 388px) {
	.folder-flex-wrapper {
		margin-right: 10px;
	}
}
@media (min-width: 340px) and (max-width: 365px) {
	.folder-flex-wrapper {
		margin-right: 30px;
	}
}

*/


/* end of queries for flex item folder row */






@media (min-width: 530px) and (max-width: 991px) {
	.folder {
		position: relative;
		width: 70px !important;
		height: calc(75px / 100 * 75) !important;
		perspective: 75 * 5 !important;
		font-size: $folder-font-size !important;
	}

	.folder-1 span::before,
	.folder-2 span::before,
	.folder-3 span::before,
	.folder-4 span::before,
	.folder-5 span::before,
	.folder-6 span::before {
		font-size: 13px !important;
	}

	.folder span::after {
		font-size: 13px !important;
	}
}

@media (max-width: 365px) {
	.folder {
		position: relative;
		width: 75px !important;
		height: calc(75px / 100 * 75) !important;
		perspective: 75 * 5 !important;
	}

	.folder-1 span::before,
	.folder-2 span::before,
	.folder-3 span::before,
	.folder-4 span::before,
	.folder-5 span::before,
	.folder-6 span::before {
		font-size: 13px !important;
	}

	.folder span::after {
		font-size: 13px !important;
	}
}

.folder {
	position: relative;
	width: $width;
	height: calc(#{$width} / 100 * 75);
	perspective: $width * 5;
	--open-folder-content: " ";
	--folder-font-size: 16px !important;
}

/* rounded folders corners, straight paper corners */
.folder span::after {
	position: absolute;
	content: "";
	border-radius: 0;
}

.folder span::before,
.folder::after,
.folder::before {
	position: absolute;
	content: "";
	border-radius: calc(#{$width} / 100 * 5);
  //-webkit-font-smoothing: antialiased;
	//-webkit-backface-visibility: hidden;
}

.folder::before {
	width: 100%;
	height: 92%;
	bottom: 0;
	//background-color: $light-blue-text;
	background-color: #7296D2;
}
/* the 'paper' inside */
.folder span::after {
	width: 90%;
	height: 80%;
	bottom: 5%;
	left: 5%;
	background-color: #fff;
	z-index: 2;
	transition: transform 250ms ease 0;
	transform: translate3d(0px, 0px, 0px);
	content: var(--open-folder-content);
	border-radius: 0;
	color: $folder-font;
	@include flex-center;
}

.folder span::before {
	//background-color: $light-blue-text;
	background-color: #7296D2;
	//filter: drop-shadow(0 3px 3px $light-blue-text);
	filter: drop-shadow(0 3px 3px #7296D2);
	width: 100%;
	height: 92%;
	bottom: 0;
	/*
	left: 0;
	*/
	z-index: 4;
	transition: transform 250ms;
	transform-origin: 0 100% 0;
	outline: 1px solid transparent;
	/* Firefox anti-aliasing hack */
	transform-style: preserve-3d;
	@include flex-center;
}

.folder::after {
	top: 0;
	left: 0;
	width: 40%;
	height: 50%;
	//background-color: $light-blue-text;
	background-color: #7296D2;
	z-index: 1;
}

// disabling folder open on hover before user has inputted query
.folders-disabled {
	-webkit-backface-visibility: hidden; // prevents known issue with Chrome and transform
	backface-visibility: hidden;
}
.folders-disabled {
	input:hover + span::before {
		transform: none !important;
	}
}
// for checkboxes peaking out slightly on some mobile screens

.folder input {
	margin-left: 10px;
	margin-top: 10px;
}

.full-open span::before {
	transform: rotateX(-120deg);
}

.folder-loaded input:hover + span::before {
	transform: rotateX($angle);
}


// individual folder labels
.folder-1 span::before {
	content: 'BIO';
}

.folder-2 span::before {
	content: 'VOTES';
}

.folder-3 span::before {
	content: 'POSITIONS';
}

.folder-4 span::before {
	content: 'RATINGS';
}

.folder-5 span::before {
	content: 'SPEECHES';
}

.folder-6 span::before {
	content: 'FUNDING';
}

.dropdown-item-line {
	margin-top: 0.10rem !important;
	margin-bottom: 0.10rem !important;
}

.form-control {
	&:focus {
		outline: 0 !important;
		border-color: initial;
		box-shadow: none;
	}
}
/* search spinner */
.spin-icon {
	animation: spin 1000ms infinite linear;
}
@-webkit-keyframes spin {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0deg);
	}

	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(359deg);
	}
}
@keyframes spin {
	0% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(0deg);
	}

	100% {
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(359deg);
	}
}

.add-on .input-group {
	border-left-width: 0;
	left: -2px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.scrollable-menu {
	height: auto;
	//max-height: 200px;
	max-height: 50vh;
	//overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch; // mobile safari
}

.icon-hidden {
	display: none !important;
}
// no results message

.search-error {
	color: $brand-danger;
}
// Material Design-like circular indicator
$offset: 187;
$duration: 1.4s;

.spinner {
	animation: rotator $duration linear infinite;
}
@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(270deg);
	}
}

.path {
	stroke-dasharray: $offset;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash $duration ease-in-out infinite, colors ($duration*4) ease-in-out infinite;
}
@keyframes colors {
	0% {
		stroke: #4285F4;
	}

	25% {
		stroke: #DE3E35;
	}

	50% {
		stroke: #F7C223;
	}

	75% {
		stroke: #1B9A59;
	}

	100% {
		stroke: #4285F4;
	}
}
@keyframes dash {
	0% {
		stroke-dashoffset: $offset;
	}

	50% {
		stroke-dashoffset: $offset/4;
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: $offset;
		transform: rotate(450deg);
	}
}
// DISABLED FOLDERS
// microinteraction shake folder effect to indicate user needs to enter query
.folder-empty {
	-webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
	animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}
@-webkit-keyframes shake {
	10%,
	90% {
		-webkit-transform: translate3d(-1px, 0, 0);
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		-webkit-transform: translate3d(2px, 0, 0);
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		-webkit-transform: translate3d(-4px, 0, 0);
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		-webkit-transform: translate3d(4px, 0, 0);
		transform: translate3d(4px, 0, 0);
	}
}
@keyframes shake {
	10%,
	90% {
		-webkit-transform: translate3d(-1px, 0, 0);
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		-webkit-transform: translate3d(2px, 0, 0);
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		-webkit-transform: translate3d(-4px, 0, 0);
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		-webkit-transform: translate3d(4px, 0, 0);
		transform: translate3d(4px, 0, 0);
	}
}

.iSpy-form-control-feedback {
	position: absolute;
	top: 0.5rem;
	right: 0;
	z-index: 2;
	display: block;
	width: 34px;
	height: 34px;
	line-height: 34px;
	text-align: center;
	pointer-events: none;
}
@mixin dropdown-item-padding {
	padding: $dropdown-item-padding-y $dropdown-item-padding-x;
}

.selected-candidate-insert {
	@include dropdown-item-padding;
}
/* just .dropdown-item from _dropdown.scss but nixing the stuff that seems to cause issues on iOS */
.iSpy-dropdown-item {
	@include dropdown-item-padding;
	display: block;
	//padding: $dropdown-item-padding-y $dropdown-item-padding-x;
	clear: both;
	font-weight: $font-weight-normal;
	color: $dropdown-link-color;
	@include hover-focus {
		color: $dropdown-link-hover-color;
		text-decoration: none;
		@include gradient-bg($dropdown-link-hover-bg);
	}

	&.active,
	&:active {
		color: $dropdown-link-active-color;
		text-decoration: none;
		@include gradient-bg($dropdown-link-active-bg);
	}

	&.disabled,
	&:disabled {
		color: $dropdown-link-disabled-color;
		background-color: transparent;
		// Remove CSS gradients if they're enabled
		@if $enable-gradients {
			background-image: none;
		}
	}
}

.iSpy-form {
	width: 100% !important;
}
/*
#iSpyHeaderRow {
	width: 100% !important;
}
*/
// new

#iSpyHeaderRow {
	flex-grow: 1;
}

#ispy-header {
	margin-bottom: 0px !important;
}



.iSpy-selection-insert {
	background-color: #FFFFFF;
}


// disabling prevent zoom on mobile during focus
//@media (min-width: 300px) and (max-width: 815px) {
@media (max-width: 500px) {

	input[type="text"] {
		font-size: 16px !important;
		//font-size: 24px !important;
	}


	#ispysearch::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		font-size: 14px;
  }

	#ispysearch::-moz-placeholder { /* Firefox 19+ */
    font-size: 14px;
  }

	#ispysearch:-ms-input-placeholder { /* IE 10+ */
    font-size: 14px;
  }

  #ispysearch:-moz-placeholder { /* Firefox 18- */
    font-size: 14px;
  }

}







/* being removed/added in iSpy.js
 adding this because a general media query hide @810px doesn't make sense when the user has already made a selection and is resizing their browser */
@media (max-width: 810px) {
	.conditional-folder-hide {
		display: none !important;
	}
}



#iSpyLogo {
 max-width: 16.50rem;
}

@media (max-width: 1199px) {
 	 #iSpyLogo {
		 max-width: 14.50rem;
	 }
 }


@media (max-width: 515px) {
	 #iSpyLogo {
		 max-width: 12.50rem;
 	}
 }

@media (max-width: 365px) {
	 #iSpyLogo {
		 max-width: 10.50rem;
 	}
 }




/* -------------------- NEW FOR ICON FOLDERS ------------------- */


/* new outline for 9955 folder feedback */

.folder-icon-outline {
    text-shadow: 0 0 3px #000 !important;
}


.icon-closed-folder-text-font {
	padding-top: 0.25rem;
	//color: $white;
    color: $blue-vs;
	font-size: 0.95rem;
	font-weight: 700;
}


.icon-open-folder-text-font {
	padding-top: 1.25rem; // needs to customzied per folder
	//color: $white;
	color: $blue-vs;
    font-size: 0.80rem;
	font-weight: 700;
	-webkit-transform: skew(-34deg, 0deg);
  transform: skew(-34deg, 0deg);
}


.fa-folder-open {
	color: #f1d592 !important;
}




@media (min-width: 770px) and (max-width: 1200px) {
	.icon-open-folder-text-font {
		padding-top: 1.1rem; // needs to customzied per folder
		//color: $white;
		color: $blue-vs;
        font-size: 0.80rem;
		font-weight: 700;

		//-webkit-transform: skew(-34deg, 0deg) rotateX(34deg);
	  //transform: skew(-34deg, 0deg) rotateX(34deg);

		// nixing rotateX() from these, need to fix iOS issue with rotateX() that causes z-index conflict with folder text and dropdown menu

		-webkit-transform: skew(-34deg, 0deg);
	  transform: skew(-34deg, 0deg);

	}
}




@media (max-width: 392px) {

	.icon-closed-folder-text-font {
		padding-top: 0.25rem;
		//color: $white;
        color: $blue-vs;
		font-size: 0.85rem;
		font-weight: 700;
	}

	.icon-open-folder-text-font {
		padding-top: 1.4em;
		//color: $white;
		color: $blue-vs;
        font-size: 0.70rem;
		font-weight: 700;
		-webkit-transform: skew(-34deg, 0deg);
	  transform: skew(-34deg, 0deg);
	}


	.positions-icon-open-folder {
		padding-left: 1.4rem !important;
	}

	.speeches-icon-open-folder {
		padding-left: 1.3rem !important;
	}


}



@media (max-width: 319px) {
	.icon-closed-folder-text-font {
		padding-top: 0.25rem;
		//color: $white;
        color: $blue-vs;
		font-size: 4vw;
		font-weight: 700;
	}

	.icon-open-folder-text-font {
		padding-top: 5vw;
		//color: $white;
        color: $blue-vs;
		font-size: 3.5vw;
		font-weight: 700;
		-webkit-transform: skew(-34deg, 0deg);
	  transform: skew(-34deg, 0deg);
	}

	.bio-icon-open-folder {
		padding-left: 8vw !important;
	}
	.positions-icon-open-folder {
		padding-left: 6vw !important;
	}
	.votes-icon-open-folder {
		padding-left: 7vw !important;
	}
	.ratings-icon-open-folder {
		padding-left: 6vw !important;
	}
	.speeches-icon-open-folder {
		padding-left: 6vw !important;
	}
	.funding-icon-open-folder {
		padding-left: 7vw !important;
	}

}

.icon-open-folder-text-font:hover{
	cursor: pointer;
}


/* NEED QUERIES FOR ALL OF THESE !!! */
.bio-icon-open-folder {
	padding-left: 1.5rem;
}
.votes-icon-open-folder {
	padding-left: 1.4rem;
}

.positions-icon-open-folder {
	padding-left: 1.6rem;
}

@media (min-width: 1201px) {
	.positions-icon-open-folder {
		padding-left: 1.6rem;
	}
}


@media (min-width: 393px) and (max-width: 767px) {
	.positions-icon-open-folder {
	   padding-left: 1.7rem;
	 }
}




.ratings-icon-open-folder {
	padding-left: 1.7rem;
}
.speeches-icon-open-folder {
  padding-left: 1.5rem;
}
.funding-icon-open-folder {
  padding-left: 1.6rem;
}




// custom fa-stack classes

.fa-stack {
	width: 2.0em !important; // make this actual size of folder icons
}

.fa-stack-2-5x {
	font-size: 2.5em;
}




#iSpyLogo2 {
	display: none !important;
}



//@media (max-width: 766px) {
@media (max-width: 991px) {
	#iSpyLogo1 {
		display: none !important;
	}
	#iSpyLogo2 {
		display: unset !important;
	}
}




/* fa-3x base size of 3em */
.fa-customize-size {
	font-size: 2.8em !important;
}

@media (min-width: 1201px ) {
	.fa-customize-size {
		font-size: 3em !important;
	}
}

@media (max-width: 767px) {
	.fa-customize-size {
		font-size: 3em !important;
	}
}

@media (max-width: 392px) {
	.fa-customize-size {
		font-size: 2.5em !important;
	}

	.icon-folder-col {
		padding-right: 5px !important;
		padding-left: 5px !important;
	}
}


/* aping .col-auto */
@media (max-width: 324px) {
	.icon-folder-row-col-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
}

/* after 318px for due diligence sake, use vw to determine .fa-customize-size {} */
@media (max-width: 318px) {
	.fa-customize-size {
		font-size: 12vw !important;
	}

	.icon-folder-text-font {
		font-size: 3.5vw !important;
	}

}

.iSpy-outter-col {
	margin-top: 1.1rem !important;
}



.ispy-folder-icon-btn {


	//color: #7296D2;
    color: #f1d592;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	border-radius: 0.2rem;
	outline: 0;

	-webkit-transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);

	transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);

	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1);

	will-change: box-shadow, transform;


  /*
	-webkit-box-shadow: 3px -5px 5px -1px #85a8c0, 3px 12px 5px -1px #85a8c0, 3px 29px 5px -1px #85a8c0;

	box-shadow: 3px -5px 5px -1px #85a8c0, 3px 12px 5px -1px #85a8c0, 3px 29px 5px -1px #85a8c0;
	*/


}

.ispy-folder-icon-btn:focus, .ispy-folder-icon-btn:hover {

	      color: #b59d63 !important;
        //color: #b39a5e !important;
		    //color: #496593 !important;
        background-color: transparent;

    -moz-box-shadow: 2px -7px 8px 1px rgba(108, 157, 235, 0.27), 2px 11px 8px 3px rgba(108, 157, 235, 0.27), 2px 30px 8px 1px rgba(108, 157, 235, 0.27);

		-webkit-box-shadow: 2px -7px 8px 1px rgba(108, 157, 235, 0.27), 2px 11px 8px 3px rgba(108, 157, 235, 0.27), 2px 30px 8px 1px rgba(108, 157, 235, 0.27);

		box-shadow: 2px -7px 8px 1px rgba(108, 157, 235, 0.27), 2px 11px 8px 3px rgba(108, 157, 235, 0.27), 2px 30px 8px 1px rgba(108, 157, 235, 0.27);


}

// for zip code link in warning message
.iSpy-warning-message {
	color: #ff0d0c !important;
	a {
		color: #ff0d0c !important;
		text-decoration: underline;
	}

	a:hover {
		background-color: white !important;
		box-shadow: none !important;
	}
}



.new-iSpy-input {
	  width: 100%;
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem !important;
    line-height: 1.5;
    color: black;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #737373;
    border-radius: .25rem;
    transition: border-color .15s;
    font-size: 24px !important;
}







@media(max-width: 765px) {

	.the-facts-arrow-right {
    display: none !important;
  }

	.the-facts-arrow-down {
		display: unset !important;
	}

	.the-facts-arrow-rotated {
		display: none !important;
	}
}



@media(min-width: 1080px) {

	.the-facts-arrow-right {
    display: unset !important;
  }

	.the-facts-arrow-down {
		display: none !important;
	}

	.the-facts-arrow-rotated {
		display: none !important;
	}
}




@media(max-width: 320px) {
	.new-ispy-header {
		font-size: 2.3125rem !important;
	}
}
