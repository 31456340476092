#truncatedMainVetoesTable {
  display: none;
}
@media (max-width: 500px) {
  #truncatedMainVetoesTable {
    display: unset;
  }

  #mainVetoesTable {
    display: none;
  }
}
@media (max-width: 320px) {
  #vetoesIssueDropdownMenuButton {
    width: 290px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.vetoes-dropdowns button {
  font-weight: 700;
}

.vetoes-dropdowns h4 {
  margin-bottom: 0;
}
// using data-container approach for customizing tooltip
.minimum-input-length-tooltip-warning .tooltip-inner {
  background-color: rgba(244, 67, 54, 0.8) !important;
  color: #fff !important;
}

/*
.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #00cc00 !important;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #00cc00 !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #00cc00 !important;
}
*/

.minimum-input-length-tooltip-warning .tooltip.bs-tooltip-top .arrow:before {
  border-top-color: rgba(244, 67, 54, 0.8) !important;
}

.vetoes-detail-list {
  li {
    padding-left: 0;
    font-weight: 700;
  }

  a {
    text-decoration: underline;
  }

  span {
    margin-right: 0 !important;
  }
}

.vetoes-detail-section-header {
  h3 {
    margin-bottom: 0;
  }

  hr {
    margin-top: 0;
  }
}

.vetoes-detail-link {
  text-decoration: underline !important;
  //font-weight: 700;
  font-size: 18px;
}

.key-votes-detail-text {
  margin-top: 20px;
}

.vetoes-details-alert {
  b {
    font-size: 18px;
  }

  a,
  p {
    color: white !important;
  }

  p {
    margin-bottom: 0;
  }

  a:focus,
  a:hover {
    text-decoration: underline;
    color: white;
  }
}


.vetoes-related-issues-list-item {
  font-size: 0.875rem !important;
  padding-top: 0px !important;
}


.vetoes-detail-collapse-header-icons {
  margin-right: 0.5rem;
  float: left !important;
  position: relative;
  transform: none !important;
  -webkit-transform: none !important;
  margin-top: -2px;
}
