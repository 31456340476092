.evals-category-dropdown {
  padding: 25px 10px 5px 0;
}

.evaluations-category-links {
  color: #002664 !important;
  font-size: 0.875rem;
}

.evaluations-category-links:focus,
.evaluations-category-links:hover {
  color: #001c4b !important;
  text-decoration: none !important;
}

.evaluations-category-header {
  h4 {
    font-weight: 700 !important;
  }
}

.evaluations-chosen-category-title {
  h4 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  hr {
    margin-top: 0px !important;
  }
}


@media (max-width: 768px) {
  #evaluationsAccordion {
    .card-collapse .card-header a i {
      float: none !important;
      top: 4px;
      position: relative;
    }
  }
}


.evaluations-alert-warning {
  margin: 1rem !important;
}


.evaluations-table {
  td {
    font-size: 1.0625rem !important;
    vertical-align: none !important;
  }
}

.evaluations-table-body {
  tr:nth-child(2) {
    td {
      border-top: none !important;
    }
  }
}

.evaluations-icon-links-row {
  display: none;
}

.evaluations-break-table-row {
  td {
    display: none;
  }
}
@media (max-width: 375px) {
  .evaluations-category-header-icons {
    display: none;
  }

  .evaluations-icon-links-row {
    display: unset;
  }

  .evaluations-table-body {
    tr:nth-child(2) {
      td {
        border-color: rgb(221, 221, 221) !important;
        border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
      }
    }

    tr:first-child {
      td {
        border-top: none !important;
        padding: 8px !important;
      }
    }
  }

  .evaluations-table-row-primary {
    display: none;
  }

  .evaluations-break-table-row {
    td {
      display: unset;
      border-top: none !important;
    }
  }
}

.evaluations-candidate-endorsement-item {
  font-size: 1.0625rem;
  padding: 8px 0px !important;
  
  a {
    margin-right: 0 !important;
  }
}

.evaluations-endorsement-header {
  h4 {
    font-weight: 700;
  }
}


.evaluations-items-break {
  display: none;
}

/* .col- breakpoint */
@media (max-width: 576px) {
  .evaluations-item-primary {
    display: none;
  }

  .evaluations-items-break {
    display: unset;
  }
}



.accordion-rating-header-link {
  color: #002664 !important;
  font-size: 1.0625rem !important;
}

.accordion-rating-header-link:hover {
  color: #001c4b !important;
  text-decoration: none !important;
}



.evaluations-endorsements-readmore-btn {
 font-size: .75rem !important;
 font-weight: 700;
}
