



@media (max-width: 767px) {
    .voteeasy-banner2-image-share {
        width: 100% !important;
    }
  }
  

