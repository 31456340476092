/*
could use power transforms if using SVG + JS version of Font Awesome
*/
.fa-adjust {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.pct-alerts {
  p {
    font-size: 1rem !important;
    color: white !important;
    margin-bottom: 0px;
  }
  margin-bottom: 18px;
  margin-top: 18px;
}

/* green position icons */
.fa-adjust, .fa-circle {
  color: #55b559 !important;
}

.returned-PCT-header-icon > i {
  font-size: .875rem !important;
}

.returned-PCT-header {
  font-weight: bold !important;
  margin-top: 0px !important;
}

@media (max-width: 400px) {
  #returnedPCTHeader > h5 {
    font-size: .875rem !important;
  }
}


#candidatePCTTable > table > tbody > tr:first-child > td {
    border: none !important;
}

.returnedPct-citations-list > li {
  padding: 1rem !important;
}

#citationsRow > td {
  border: none !important;
}

.additional-info-chevron {
  transition: all 0.25s ease;
}

.additional-info-chevron:hover {
  color: #e91e63;
}

.rotate-chevron {
  transform: rotateZ(180deg);
}

#collapseReturnedPCTResultsBtn {
  padding-top: 0.46875rem !important;
  padding-right: 1rem !important;
  padding-bottom: 0.46875rem !important;
  padding-left: 1rem !important;
  white-space: normal;
  font-size: 1.5625rem !important;
  font-weight: 700;
  line-height: 1.4em !important;
}

@media (max-width: 414px) {
  #collapseReturnedPCTResultsBtn {
    font-size: 1.125rem !important;
  }
}



/* because we want the opposite of what works for the desktop grid layout in accordion header at mobile break point */

.pct-accordion-assigned-item-post-break {
  display: none;
}

@media(max-width: 575px) {
  .pct-accordion-assigned-item-post-break {
    display: unset;
  }
  .pct-accordion-assigned-item-pre-break {
    display: none;
  }
}
