
.campaign-finance-dropdown-menu {
  width: 100% !important;
  overflow: auto;
  max-height: 300px !important;
  -webkit-overflow-scrolling: touch;
}



@media (max-width: 375px) {

  .candidate-finance-table > td {
    font-size: 0.75rem !important;
  }

  #campaignFinanceCardBody {
      padding-left: .5rem !important;
      padding-right: .5rem !important;
  }

}


@media (max-width: 415px) and (min-width: 376px){
  #campaignFinanceCardBody {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
  }
}


@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor:pointer;
  }
}
