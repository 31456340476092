.user-panel-card-header a {
  font-size: 1.2rem !important;
}

.user-panel-state-select {
  .btn-group {
    margin-top: 0px !important;
  }

  .dropdown-menu {
    max-height: 200px !important;

  }
}


.change-password-form ul {
  padding-left: 13px;
  font-size: 0.75rem;
}


// remove item from tracking list
.remove-tracked-item {
  transition: .3s transform ease-in-out;
  transform: rotateX(90deg);
}


.tracked-items-list {
  li {
    padding: 0.25rem;
    //font-size: 0.95rem;
    font-size: 18px;
  }

  a {
    margin-right: 0.2rem !important;
  }

  button {
    font-weight: 700;
  }
}


@media (max-width: 575px) {
  .zip-dash {
    display: none;
  }
}


.user-name-check-alert-success {
  font-size: 18px !important;
  font-weight: 700;
}


// let's hide the reCAPTCHA V3
// https://developers.google.com/recaptcha/docs/faq
.grecaptcha-badge {
  visibility: hidden;
}

.alert-success {
  font-weight: 700;
}
