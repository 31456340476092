.script-font {
    font-family: $font-family-script;
}

.drop-cap {
    font-size: $drop-cap;
}

ul.nostyle {
    list-style-type: none;
}

ul.nostyle li {
    padding-bottom: 0.5rem;
}

.card .card-header-primary,
.card.bg-primary,
.card.card-rotate.bg-primary .front,
.card.card-rotate.bg-primary .back {
    background: linear-gradient(60deg, $blue-vs, $blue-900);
}

.no-word-wrap {
    white-space: nowrap;
}


.alt-grey-bg {
    background-color: $grey-100;
}

.back-to-top {
    cursor: pointer;
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    display: none;
    z-index: 3;
}