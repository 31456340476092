// Bootstrap brand color customization

/*     brand Colors              */

$brand-primary:              $blue-vs !default;
$brand-info:                 $indigo-vs !default;
$brand-success:              $green-vs !default;
$brand-warning:              $orange-500 !default;
$brand-danger:               $red-vs !default;
$brand-rose:                 $pink-500 !default;
$brand-inverse:              $black-color !default;
$brand-footerlb:             $light-blue-text !default;
